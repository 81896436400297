import { isNotNil } from "./typeguards"
import { Maybe } from "./types"

export function formatPercent(value: number, digits = 2): string {
  return new Intl.NumberFormat("cs", { style: "percent", maximumFractionDigits: digits }).format(value)
}

export function formatCurrency(value: number, currency: "CZK"): string {
  if (Math.abs(value) < 0.005) {
    value = 0
  }

  return new Intl.NumberFormat("cs", { style: "currency", maximumFractionDigits: 2, currency }).format(value)
}

export function formatNumber(value: number, digits = 2): string {
  return new Intl.NumberFormat("cs", { maximumFractionDigits: digits, minimumFractionDigits: digits }).format(value)
}

export function formatRelativeTime(seconds: number): string {
  return new Intl.RelativeTimeFormat("cs", { style: "narrow" }).format(seconds, "seconds")
}

export function formatDuration(durationInSeconds: number): string {
  const hours = Math.floor(durationInSeconds / 3600)
  const minutes = Math.floor((durationInSeconds % 3600) / 60)
  const seconds = durationInSeconds % 60
  return `${hours}h ${minutes}m ${seconds}s`
}

export function formatSIUnitPrefixes(value: number) {
  const prefixName = [
    "quecto",
    "ronto",
    "yocto",
    "zepto",
    "atto",
    "femto",
    "pico",
    "nano",
    "micro",
    "milli",
    "",
    "kilo",
    "mega",
    "giga",
    "tera",
    "peta",
    "exa",
    "zetta",
    "yotta",
    "ronna",
    "quetta",
  ]

  const prefixSymbol = [
    "q",
    "r",
    "y",
    "z",
    "a",
    "f",
    "p",
    "n",
    "μ",
    "m",
    "",
    "k",
    "M",
    "G",
    "T",
    "P",
    "E",
    "Z",
    "Y",
    "R",
    "Q",
  ]

  let magnitude = Math.ceil(Math.log10(Math.abs(value)))

  // Set the magnitude to zero for log10(0), which returns -Infinity
  if (!Number.isFinite(magnitude)) {
    magnitude = 0
  }

  // Calculate prefix array index
  let index = Math.floor(magnitude / 3) + 10

  // Clamp index to lowest / highest prefix
  index = Math.min(20, Math.max(0, index))

  // Exponent to scale the value by
  const exponent = (index - 10) * 3

  const prefix = prefixName[index]
  const symbol = prefixSymbol[index]

  return { exponent, prefix, symbol, value: value / 10 ** exponent }
}

export function formatPower(input: number, digits?: number) {
  const { symbol, value } = formatSIUnitPrefixes(input)
  return `${formatNumber(value, digits)} ${symbol}W`
}

export function formatOptionalPower(input: Maybe<number>, digits?: number) {
  return isNotNil(input) ? formatPower(input, digits) : "-- W"
}

export function formatPowerToKiloWatt(inputInWatt: number, digits?: number) {
  return `${formatNumber(inputInWatt / 1000, digits)} kW`
}

export function formatEnergy(input: number, digits?: number) {
  const { symbol, value } = formatSIUnitPrefixes(input)
  return `${formatNumber(value, digits)} ${symbol}Wh`
}

export function formatEnergyToKiloWattHour(inputInWattHours: number, digits?: number) {
  return `${formatNumber(inputInWattHours / 1000, digits)} kWh`
}

export function formatPricePerEnergy(input: Maybe<number>, digits?: number) {
  return `${isNotNil(input) ? formatNumber(input, digits) : "--"} Kč/kWh`
}

export function formatBytes(input: number, digits?: number) {
  const { symbol, value } = formatSIUnitPrefixes(input)
  return `${formatNumber(value, digits)} ${symbol}B`
}

export function formatEnergyUnit(order: number) {
  const { symbol } = formatSIUnitPrefixes(order)
  return `${symbol}Wh`
}
