import { add, Duration, isAfter } from "date-fns"
import dayjs from "dayjs"

import { clamp } from "./math"

export const supportedGranularities = [5, 15, 60, 300, 900, 3600] as const

export type SupportedGranularity = (typeof supportedGranularities)[number]

export function formatMinutesAsTimeString(minutes: number): string {
  const input = clamp(minutes, 0, 1440 - 1)
  const hours = Math.floor(input / 60)
  const minutesLeft = input % 60
  return `${hours.toString().padStart(2, "0")}:${minutesLeft.toString().padStart(2, "0")}`
}

export function parseTimeStringAsMinutes(timeString: string): number | undefined {
  const [hours = 0, minutes = 0] = timeString.split(":").map((val) => parseInt(val, 10))
  const result = hours * 60 + minutes
  return Number.isNaN(result) ? undefined : result
}

/**
 * @deprecated use generateDateArrayV2 instead
 */
export function generateDateArray(
  start: Date,
  end: Date,
  options?: { unit?: dayjs.ManipulateType; interval?: number; includeEnd?: boolean },
): Date[] {
  const { unit = "day", interval = 1, includeEnd = true } = options || {}

  const isStartAfterEnd = dayjs(start).isAfter(end)
  const from = isStartAfterEnd ? end : start
  const to = isStartAfterEnd ? start : end

  const dateArray = []

  let current = from
  while (current <= to) {
    if (!includeEnd && current >= to) {
      break
    }

    dateArray.push(new Date(current))
    current = dayjs(current).add(interval, unit).toDate()
  }

  return isStartAfterEnd ? dateArray.reverse() : dateArray
}

export function generateDateArrayV2(
  start: Date,
  end: Date,
  options?: { unit?: keyof Duration; interval?: number; includeEnd?: boolean },
): Date[] {
  const dateArray = []
  const { includeEnd = true, interval = 1, unit = "days" } = options || {}

  const isStartAfterEnd = isAfter(start, end)
  const from = isStartAfterEnd ? end : start
  const to = isStartAfterEnd ? start : end

  let current = from
  while (current <= to) {
    if (!includeEnd && current >= to) {
      break
    }

    dateArray.push(new Date(current))
    current = add(current, { [unit]: interval })
  }

  return isStartAfterEnd ? dateArray.reverse() : dateArray
}

export function daysToSeconds(days: number) {
  return days * 24 * 60 * 60
}

export function hoursToSeconds(hours: number) {
  return hours * 60 * 60
}

// TODO test and rename
export function stripDate(date: Date) {
  return dayjs(date).set("year", 0).set("month", 0).set("date", 0).set("seconds", 0).set("milliseconds", 0).toDate()
}

export function setFullDate(date: Date, newDate: Date) {
  return dayjs(date)
    .set("date", newDate.getDate())
    .set("month", newDate.getMonth())
    .set("year", newDate.getFullYear())
    .set("seconds", 0)
    .set("milliseconds", 0)
    .toDate()
}

export function getStartOfSlot(date: Date) {
  return dayjs(date)
    .minute(Math.floor(date.getMinutes() / 15) * 15)
    .second(0)
    .millisecond(0)
}

export function getEndOfSlot(date: Date) {
  return getStartOfSlot(date).add(15, "minutes")
}

export function getClosestSlot(date: Date) {
  return date.getMinutes() % 15 < 7.5 ? getStartOfSlot(date) : getEndOfSlot(date)
}

/**
 * Returns the start of the granularity slot for the given date.
 * @param date The date to round
 * @param granularitySeconds The granularity in seconds.
 */
export function getStartOfGranularitySlot(date: Date, granularitySeconds: SupportedGranularity) {
  const granularityMilliseconds = granularitySeconds * 1000
  return dayjs(Math.floor(date.getTime() / granularityMilliseconds) * granularityMilliseconds)
}

/**
 * Returns the end of the granularity slot for the given date.
 * @param date
 * @param granularitySeconds
 */
export function getEndOfGranularitySlot(date: Date, granularitySeconds: SupportedGranularity) {
  return getStartOfGranularitySlot(date, granularitySeconds).add(granularitySeconds, "seconds")
}

export function clampDateStart(date: Date, minDate: Date): Date {
  return date < minDate ? minDate : date
}

export function clampDateEnd(date: Date, maxDate: Date): Date {
  return date > maxDate ? maxDate : date
}

export function clampDate(date: Date, minDate: Date, maxDate: Date): Date {
  return clampDateEnd(clampDateStart(date, minDate), maxDate)
}

/**
 * Rotates Node.JS weekdays so that it is indexed from 0 on Monday
 */
export function mapWeekdayIndexToStartOnMonday(nodeWeekday: number): number {
  // dayjs = 0 (Sunday) to 6 (Saturday)
  // python = Monday is 0 and Sunday is 6
  return (nodeWeekday + 6) % 7
}

/**
 * Rotates weekday index starting from 0 on Monday so that it complies by Node.JS indexing, being 0 = Sunday
 */
export function mapWeekdayStartingOnMondayToStartOnSunday(customWeekday: number): number {
  // dayjs = 0 (Sunday) to 6 (Saturday)
  // python = Monday is 0 and Sunday is 6
  return (customWeekday + 1) % 7
}
